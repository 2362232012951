import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import BlogTwo from "@/components/blog-two";
import { Helmet as Head } from "react-helmet";

const Blog = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Blog Page">
        <Head>
          <title>Our Blogs | Nifixeci</title>
          <meta
            name="description"
            content="Go through our blog posts and enhance your knowledge regarding pest control."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="Blogs" name="Blog" />
        <BlogTwo />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Blog;
